.contact-form-wrapper {
  display: grid;
  grid-template-rows: min-content auto;
  grid-column-start: 2;
  grid-column-end: 4;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(6px);
  position: relative;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  grid-gap: 10px;
}
@media screen and (max-width: 600px) {
  .contact-form-wrapper {
    grid-column-start: 1;
  }
}