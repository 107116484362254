.pages-wrapper {
	width: 100%;
	min-height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
  position: relative;
  background-color: rgba(32, 32, 43, 1);
}
.pages-title {
  font-size: 2.6em;
  white-space: nowrap;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
}
.pages-subtitle {
  font-size: 1.6em;
  white-space: nowrap;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}
.pages-description {
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
}
.pages-primary-button {
  width: min-content;
  height: min-content;
  padding: 10px;
  padding-right: 12px;
  padding-left: 12px;
  cursor: pointer;
  font-size: 1em;
  line-height: 16px;
  white-space: nowrap;
  background: rgba(136, 115, 239, 0.8);
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
  user-select: none;
  border: none;
  letter-spacing: 0.8px;
}
.pages-secondary-button {
  width: min-content;
  height: min-content;
  padding: 10px;
  padding-right: 12px;
  padding-left: 12px;
  cursor: pointer;
  font-size: 1em;
  line-height: 16px;
  white-space: nowrap;
  background: rgba(220, 220, 220, 1);
  color: rgba(0, 0, 0, 1);
  user-select: none;
  border: none;
}
.copy-button {
  fill: rgba(255, 255, 255, 1);
  cursor: pointer;
}
.copy-tooltip {
  position: absolute;
  z-index: 6;
  background-color: rgba(33, 33, 43, 1);
  padding: 4px;
  top: -37px;
  right: -12px;
  color: rgba(255, 255, 255, 1);
}
.copied {
  position: absolute;
  z-index: 6;
  background-color: rgba(126, 126, 132, 1);
  padding: 5px;
  top: -37px;
  right: -12px;
  color: rgba(255, 255, 255, 1);
}
@media screen and (max-width: 600px) {
  .pages-title {
    font-size: 2em;
  }
  .pages-description {
    font-size: 0.8em;
  }
}
@media screen and (max-width: 320px) {
  .pages-title {
    font-size: 2em;
  }
  .pages-description {
    font-size: 12px;
  }
}