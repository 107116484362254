.contact-textarea {
  font-family: Montserrat;
  width: 100%;
  border: none;
  box-sizing: border-box;
  font-size: 16px;
  padding: 10px;
  background-color: rgba(220, 220, 220, 1);
  outline: rgba(126, 126, 132, 1);
  border-radius: 0px;
}