 .landing-header {
    background: rgba(33, 33, 43, 0.6);
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    z-index: 2;
    
}
.landing-navigation-wrapper {
   display: grid;
   grid-template-columns: 1fr 1fr;
   align-items: center;
   padding-right: 16px;
}
@media screen and (max-width: 1280px) {
   .landing-header {
      grid-template-columns: 1fr 4fr 1fr;
   }
}
@media screen and (max-width: 768px) {
   .landing-header {
      grid-template-columns: 20px auto 20px;
   }
   .landing-navigation-wrapper {
      padding-right: 0;
   }
}