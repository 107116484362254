.tab-buttons-wrapper {
	display: flex;
	gap: 5px;
	padding: 2px;
	backdrop-filter: blur(6px);
	background-color: rgba(0, 0, 0, 0.2);
}
.tab-button {
	padding: 3px;
	padding-right: 20px;
	padding-left: 20px;
	cursor: pointer;
	white-space: nowrap;
	background: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(6px);
	border: none;
	border-radius: 2px 2px 0 0;
}
.tab-button:active {
	background-color: rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(6px);
	color: rgba(0, 0, 0, 1);
}
.tab-button:focus {
	background-color: rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(6px);
	color: rgba(0, 0, 0, 1);
}
.tab-button:target {
	background-color: rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(6px);
	color: rgba(0, 0, 0, 1);
}
@media screen and (max-width: 600px) {
	.tab-button {
		padding-right: 14px;
		padding-left: 14px;
	}
}