.page3-wrapper {
	display: grid;
	grid-template-columns: 1fr 2fr 1fr;
	min-height: 100%;
	align-content: center;
}
.page3 {
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-gap: 20px;
	padding-top: 20px;
}
.page3-title {
	font-size: 1.2em;
	color: rgba(255, 255, 255, 1);
	padding-bottom: 5px;
}
@media screen and (max-width: 1280px) {
	.page3-wrapper {
		grid-template-columns: 1fr 4fr 1fr;
	}
}
@media screen and (max-width: 1000px) {
	.page3-wrapper {
		grid-template-columns: 20px auto 20px;
	}
}
@media screen and (max-width: 600px) {
	.page3 {
		grid-template-columns: 1fr;
		grid-template-rows: auto min-content;
		grid-column-gap: 0px;
		grid-row-gap: 10px;
		padding-top: 10px;
	}
}