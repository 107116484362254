.card-wrapper {
	position: relative; 
	cursor: pointer;
	display: grid;
	grid-template-rows: min-content auto min-content;
	background-color: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(6px);
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	grid-gap: 5px;
}
.card-thumbnail {
	max-width: 100%;
	width: auto;
	height: auto;
	object-fit: contain;
}
.card-background {
	position: absolute;
	width: 100%; 
	height: 100%; 
	top: 0; 
	left: 0; 
	z-index: -1;
	display: grid;
}
@media screen and (max-width: 600px) {
	.card-title {
		display: none;
	}
	.card-wrapper {
		padding: 0;
		padding-left: 5px;
		padding-right: 5px;
	}
}