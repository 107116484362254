.mobile-arrow-right {
	position: absolute;
	top: 50%;
	right: 10px;
	width: 0; 
	height: 0; 
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 10px solid rgba(126, 126, 132, 1);
	cursor: pointer;
	z-index: 3;
}
.mobile-arrow-left {
	position: absolute;
	top: 50%;
	left: 10px;
	width: 0; 
	height: 0; 
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-right: 10px solid rgba(126, 126, 132, 1);
	cursor: pointer;
	z-index: 3;
}
.mobile-arrow-right:hover {
  border-left: 10px solid rgba(255, 255, 255, 1);
}
.mobile-arrow-left:hover {
  border-right: 10px solid rgba(255, 255, 255, 1);
}