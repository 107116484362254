.canvas-grid-wrapper {
	position: fixed; 
	top: 0; 
	left: 0; 
	width: 100%; 
	height: 100%; 
	pointer-events: none; 
}
.svg-polygon {
	transition: fill 1s ease;
}