.page1-wrapper {
	display: grid;
	grid-template-columns: 1fr 2fr 1fr;
	padding-top: 20px;
}
.page1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	overflow: hidden;
	z-index: 2;
	background-color: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(6px);
	align-items: center;
	padding-top: 20px;
	padding-bottom: 20px;
}
@media screen and (max-width: 1280px) {
	.page1-wrapper {
		grid-template-columns: 1fr 4fr 1fr;
	}
}
@media screen and (max-width: 1000px) {
	.page1-wrapper {
		grid-template-columns: 20px auto 20px;
		padding-top: 10px;
	}
}
@media screen and (max-width: 600px) {
	.page1 {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.page1-wrapper {
		min-height: 75%;
	}
}