.carousel-track {
  display: flex;
  overflow: hidden;
}
.carousel-item {
  display: inline-flex;
  width: 100%;
  height: 100%;
}
.inner {
  white-space: nowrap;
  transition: transform 0.5s;
}