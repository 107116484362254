.available-wrapper {
    display: flex;
    align-items: center;
    color: rgba(172, 208, 56, 0.6);
    gap: 5px;
    padding-bottom: 5px;
    padding-left: 3px;
}
.available-circle {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgba(172, 208, 56, 0.6);
}
.available-circle:after {
    content: '';
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    animation: circle-pulse 2s infinite;
    border-radius: 50%;
}

@keyframes circle-pulse {
    0% 
    {
        box-shadow: 0 0 0 0 rgba(172, 208, 56, 0.6);
    }
    50% 
    {
        box-shadow: 0 0 0 6px rgba(172, 208, 56, 0);
    }
    100% 
    {
        box-shadow: 0 0 0 0 rgba(172, 208, 56, 0);
    }
}