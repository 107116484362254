.profile-picture-wrapper {
	display: grid; 
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	padding-top: 20px;
}
.profile-picture {
	max-width: 100%;
	max-height: 100%;
	z-index: 1; 
	pointer-events: none;
	justify-self: center;
	align-self: end;
}
@media screen and (max-width: 600px) {
	.profile-picture-wrapper {
		display: none;
	}
}