.slider-tabs-wrapper {
  display: grid; 
  grid-template-rows: min-content auto;
  grid-column-start: 1;
  grid-column-end: 3;
}
.slider-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}