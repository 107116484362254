.page2-wrapper {
	min-height: 100%;
	display: grid;
	grid-template-columns: 1fr 2fr 1fr;
}
.page2 {
	padding-top: 20px;
	overflow: hidden;
	display: grid;
	grid-template-rows: min-content auto;
	grid-gap: 20px;
}
.slider-item-wrapper {
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr;
  overflow: hidden;
  grid-gap: 20px;
  align-self: start;
}
.slider-tabs-wrapper {
	display: grid;
	grid-template-rows: min-content auto;
	position: relative;
}
@media screen and (max-width: 1280px) {
	.page2-wrapper {
		grid-template-columns: 1fr 4fr 1fr;
	}
}
@media screen and (max-width: 1000px) {
	.page2-wrapper {
		grid-template-columns: 20px auto 20px;
	}
}
@media screen and (max-width: 600px) {
	.slider-item-wrapper {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr;
		grid-gap: 0;
	}
	.page2 {
		gap: 10px;
		padding-top: 10px;
	}
}