.social-button-wrapper {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: linear-gradient(
    90deg, 
    rgba(227, 203, 62, 1) 0%, 
    rgba(90, 81, 24, 1) 50%, 
    rgba(90, 81, 24, 1) 60%, 
    rgba(227, 203, 62, 1) 100%
  );
  animation: steam 80s linear infinite;
}
.social-button-wrapper:hover {
  background: rgba(255, 255, 255, 1);
}
@keyframes steam {
  from {background-position: 0px 600px;}
    to {background-position: -800px 600px;}
}