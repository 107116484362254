.contact-info-wrapper {
   background-color: rgba(0, 0, 0, 0.2); 
   backdrop-filter: blur(6px);
   display: grid;
   grid-template-rows: min-content auto;
   padding: 10px;
   padding-left: 20px;
   padding-right: 20px;
   overflow: hidden;
}
.contact-item-wrapper {
   display: grid; 
   grid-template-columns: 30px auto min-content;
   align-items: center;
}
.dog-image {
   max-width: 100%;
   max-height: 100%;
}
.social-info {
   display: grid;
   padding-top: 10px;
   grid-gap: 20px;
   grid-template-rows: min-content auto;
   a {
      margin: 0;
      padding: 0;
   }
}
@media screen and (max-width: 600px) {
   .social-info {
      grid-template-columns: 1fr 1fr;
      padding-top: 10px;
   }
   .dog-image {
      padding-left: 0;
      max-width: 100%;
   }
}